<template>
	<div>
		<CustomTable
			ref="table"
			id_table="horse_actes_gyneco"
			primaryKey="actes_id"
			:items="actes"
			:busy.sync="table_busy"
			:display_action_button="!horseFicheReadOnly"
			:checkboxes="!horseFicheReadOnly"
			:buttonActionEventOnly="true"
			:uniqueEventId="$options.name"
			:externSlotColumns="['ordonnance']"
		>

		<template v-slot:[`custom-slot-cell(ordonnance)`]="{ data }">
			<div 
				v-if="data.actes_document_lien.length > 0" 
			>
				<div v-for="document of data.actes_document_lien" :key="document.document_id">
					<a href="#" @click="viewDocumentOrdonnance(document)">
						{{ document.document_name }}
					</a>
				</div>
				
			</div>
			<div 
				v-else-if="data.actetype.actestype_needordonnance" 
			>
				<a href="#" @click="addOrdonnance([data])">
					<font-awesome-icon :icon="['far', 'file-medical-alt']"  />
				</a>
			</div>
		</template>

		</CustomTable>

        <ModalReplan 
            ref="modal_actes_replan" 
            :callback_validation="applyReplanActe"
            @ok="onShuttersSubmit"
        />

		<b-modal size="xl" ref="ordonnanceModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			<OrdonnanceForm :horses_ids="horse_ids" :from_mediatheque="false" @documents="handleOrdoForm"/>
        </b-modal>

		<b-modal size="xl" ref="ordonnanceRenameModal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("ordonnance.ajouter_ordonnance") }}
            </template>
			
			<OrdonnanceRename :horses_ids="horse_ids" :from_mediatheque="false" :documents.sync="documents" @success="HandleOrdoRename" :acte_ids="acte_ids"/>
        </b-modal>

		<b-modal :modal-class="'modal-pdf'" size="xl" ref="modalViewDocumentOrdonnance" hide-footer>
            <template slot="modal-header" slot-scope="{ close }">
                <h2 class="mx-auto my-auto">{{ $t('ordonnance.document_visualisation') }}</h2>
                <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
            </template>

			<OfflineMedia
				:filename="modal_document_filename"
				customClass="w-100"
			/>
        </b-modal>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import CustomTable from "GroomyRoot/components/Table/CustomTable";
    import ActesMixin from "@/mixins/Actes.js";
    import Navigation from "@/mixins/Navigation.js";
    import Gynecologie from '@/mixins/Gynecologie.js'
    import Shutter from '@/mixins/Shutter.js'
	import ShutterActs from '@/mixins/shutters-manager/Acts.js'
	import _cloneDeep from 'lodash/cloneDeep'
	import Constants from 'Constants'
	import Documents from "@/mixins/Documents.js"

    export default {
        name: "ActesHorse",
        mixins: [
			ActesMixin,
			Gynecologie,
            Navigation,
            Shutter,
            ShutterActs,
			Documents
        ],
        props: {
            horse_id: { type: Number, default: null },
			actual_season_mare_stallion: { type: Object, default: null },
        },
    	data() {
			return {
				/* Configuration du tableau : */
				table_busy: false,/* Définit si la table n'est pas encore chargée */
				actes: [],
				actual_season: null,

				events_tab: {
					'TableAction::DeleteActes::ActesHorse': this.deleteActePostConfirmation,
					'TableAction::acteAjout::ActesHorse'  : this.openActShutter,
					'TableAction::acteAjoutEcho::ActesHorse'  : this.openActShutterEcho,
					'TableAction::acteAjoutDG::ActesHorse'  : this.openActShutterDG,
					'TableAction::ReplanActe::ActesHorse' : this.replanActesModal,
                    'TableAction::goToDupliqueActe': this.dupliqueActe
				},

				last_operation: '',
				last_row_id: 0,
				reloadMethod: 'reload',
				modal_document_filename: null,
				horse_ids: [],
				acte_ids: [],
                documents: [],
			}
		},
		methods: {
			async loadActesGyneco(season) {
				this.table_busy = true

				if(season) {
					this.actual_season = season
				}

				this.actes = await this.getGynecoActsFromHorse(this.horse_id, this.actual_season.begin, this.actual_season.end)

				this.table_busy = false
			},

            /* Ouverture des panels de gestion d'actes */
            openActShutter(params) {
                const operation = params.state || 'add'
                const row_id = params.pk_id || params.acte_id || params.horse_id

				this.shutterPanel().keepGiven('horse-gyneco')

                if(operation == this.last_operation && row_id == this.last_row_id) {
                    this.last_operation = ''
                    this.last_row_id = 0

                    return
                }
                else if(operation == 'add') {
                    this.setupActAdd(this.horse_id.toString(), 'REPRO')
                }
                else if(operation == 'modification') {
                    this.setupActEdit(row_id, params.lot)
                }
                else if(operation == 'validation') {
                    this.setupActValidation(row_id)
                }

                this.last_operation = operation
                this.last_row_id = row_id
            },
            /* Ouverture des panels de gestion d'actes */
            openActShutterEcho() {
				this.shutterPanel().keepGiven('horse-gyneco')
                this.setupActAdd(this.horse_id.toString(), 'REPRO', 'REPRO_ECHOGRAPHIE')
            },
            /* Ouverture des panels de gestion d'actes */
            openActShutterDG() {
				this.shutterPanel().keepGiven('horse-gyneco')
                this.setupActAdd(this.horse_id.toString(), 'REPRO', 'REPRO_DIAGNOSTIC_DE_GESTATION')
            },
            /* Modale de replanification */
            replanActesModal(actes) {
                this.interacted_actes = actes
				const date = actes.length === 1 ? Date.parseTz(actes[0].actes_date) : new Date()
                this.$refs.modal_actes_replan.openModal(date, actes)
            },
            /* Callback après validation de la suppression d'un acte */
            async deleteActePostConfirmation(actes) {
				await this.applyDeleteActes(actes)

				// Pour mettre à jour l'état physio, on force la synchro
				await this.$sync.force(true)
				EventBus.$emit('GynecologieHorse::reload')
				EventBus.$emit('HorseFiche::refreshHorseStatus')
            },
			reload(season) {
				this.$refs.table.resetCachedItems()
				this.loadActesGyneco(season)
				this.shutterPanel().reload('act-list')
			},
            async onShuttersSubmit(result) {
				if(result && Array.isArray(result) && result.length > 0) {
					this.checkActsDepencies(result)
				}

				this.closeAllShutters()
				this.reload()

				// Pour mettre à jour l'état physio, on force la synchro
				await this.$sync.force(true)
				EventBus.$emit('GynecologieHorse::reload', false)
				EventBus.$emit('HorseFiche::refreshHorseStatus')
            },

			async checkActsDepencies(acts) {
				let tab_promises = []
				const validated_acts = acts.filter(act => act.acte_statut == 1)

				validated_acts.forEach(act => {

					if(act.reponses && act.reponses.length > 0) {
						tab_promises.push(new Promise(async (res, _) => {
							// Le cas d'un acte de type saillie
							const local_act = _cloneDeep(act) // copy pour garder la même instance d'act durant tout le foreach qui n'est pas synchrone
							const hasSaillieAnswers = local_act.reponses.find(rep => rep.reponse_data_hidden && rep.reponse_data_hidden.type == "stallion")
							const isSaillieActType = !!hasSaillieAnswers

							if(isSaillieActType) {
								const stallion_id = hasSaillieAnswers.reponse_data_hidden.id
								const actual_season_mare = (await this.loadSeasonMareStallionBySeasonHorse(this.horse_id, this.actual_season.id))[0]

								// Si on a déjà un étalon par défaut
								if(this.actual_season_mare_stallion) {
									// On regarde s'il est différent de celui donné. Si oui, on désactive le précédent
									if(this.actual_season_mare_stallion.seasonmarestallion_horse != stallion_id) {
										await this.editAllSeasonMareStallionDefault(actual_season_mare.seasonmare_id, { seasonmarestallion_default: false })
									}
									// Si c'est le même, pas de modif nécessaire
									else {
										return res()
									}
								}

								// On insère le nouvel étalon par défaut
								await this.addSeasonMareStallion(actual_season_mare.seasonmare_id, stallion_id, null, 1)
							}

							return res()
						}))
					}
				})

				return Promise.all(tab_promises)
			},

			async viewDocumentOrdonnance(data) {
				if(this.asking_write_access) return

				if(!this.got_write_access) {
					this.interracting_data = data
					this.action_name = 'viewDocumentOrdonnance'
					return this.askPermissionAndroid()
				}

				const filename = data.document_filename

				if((Constants.IS_IOS_RUNNING || Constants.IS_AND_RUNNING)
					&& filename.includes('.pdf')
				) {
					const blobFile = await this.$sync.loadOnDemandFile(filename)
					let reader = new FileReader()
					reader.readAsDataURL(blobFile)

					reader.onloadend = () => {
						const base64Cleanedup = this.formatBase64String(reader.result)

						if(Constants.IS_IOS_RUNNING) {
							window.nativeComm.fetchNative(() => {
								EventBus.$emit('TableAction::stopSpin')
							}, {
								filename: filename,
								base64: base64Cleanedup
							}, 'previewPdfFile')
						}
						else if(Constants.IS_AND_RUNNING) {
							window.nativeComm.fetchNative(res => {
								EventBus.$emit('TableAction::stopSpin')
							}, base64Cleanedup, 'previewPdfDocument')
						}
					}
				}
				else {
					this.$refs['modalViewDocumentOrdonnance'].show()
                	this.modal_document_filename = filename
					EventBus.$emit('TableAction::stopSpin')
				}
            },

			addOrdonnance(actes) {
				this.horse_ids = actes.map(a => a.actes_horse)
				this.acte_ids = actes.map(a => a.actes_id)
				this.$refs.ordonnanceModal.show()
            },

			handleOrdoForm(documents) {
                this.$refs.ordonnanceModal.hide()
                this.documents = documents
                this.$refs.ordonnanceRenameModal.show()
            },

            HandleOrdoRename() {
                this.$refs.ordonnanceRenameModal.hide()
            },

            closeAllShutters() {
                this.shutterPanel().keepGiven('horse-gyneco')
            },

            async dupliqueActe(acte_id) {
               const acte = await this.getActeFromId(acte_id, 'withContactAndArticles')
               this.setupActDuplication(acte)
            }
        },
        computed: {
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			}
        },
		components: {
            CustomTable,
            ModalReplan: () => import('@/components/Modals/ModalReplan'),
			OrdonnanceForm: () => import("@/components/Documents/OrdonnanceForm"),
		    OrdonnanceRename: () => import("@/components/Documents/OrdonnanceRenameForm"),
			OfflineMedia: () => import('GroomyRoot/components/Utils/OfflineMedia'),
		}
	}
</script>
